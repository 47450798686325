import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ErrorsComponent } from './core/errors/errors.component';
import { ForbiddenComponent } from './core/forbidden/forbidden.component';
import { MainTemplateComponent } from './core/main-template/main-template.component';
import { UnderConstructionComponent } from './core/under-construction/under-construction.component';
import { AuthGuard } from './security/guards/auth.guard';
import { UnitGuard } from './security/guards/unit.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/reservas',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'reservas',
    canActivate: [UnitGuard],
    loadChildren: () => import('./booking/booking.module').then((m) => m.BookingModule),
  },
  {
    path: 'vendas',
    loadChildren: () => import('./sales/sales.module').then((m) => m.SalesModule),
    // loadChildren: () => import('./marketing/marketing.module').then((m) => m.MarketingModule),
  },
  {
    path: 'b2b',
    loadChildren: () => import('./b2b/b2b.module').then((m) => m.B2bModule),
  },
  {
    path: 'escolinha',
    loadChildren: () => import('./school/school.module').then((m) => m.SchoolModule),
  },
  {
    path: 'financeiro',
    data: {
      title: 'Financeiro',
    },
    component: MainTemplateComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: UnderConstructionComponent,
      },
    ],
  },
  {
    path: '404',
    component: ErrorsComponent,
  },
  {
    path: '403',
    component: ForbiddenComponent,
  },
  { path: 'School', loadChildren: () => import('./school/school.module').then((m) => m.SchoolModule) },
  {
    path: '**',
    component: ErrorsComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
