<div class="sidebar-main--inner">
  <div class="logo">
    <img src="../../assets/images/appito-logo-rail.svg" alt="Appito Manager" />
  </div>

  <nav class="sidebar__nav" *ngIf="showMenu">
    <ul class="list-unstyled">
      <ng-container *ngFor="let m of menu; let i = index">
        <li
          class="sidebar__nav__item"
          [class.sidebar__nav__item--no-mobile]="!m.showMobile"
          [class.active]="menuActive && i === 0"
          [routerLink]="m.url"
          [routerLinkActiveOptions]="{ exact: false }"
          routerLinkActive="active"
          *ngIf="m.url !== '/more'"
        >
          <span class="sidebar__nav__item-icon">
            <span class="material-symbols-outlined"> {{ m.icon }} </span>
          </span>
          <span class="sidebar__nav__item-label typography body small" *ngIf="m.name">
            {{ m.name }}
          </span>
        </li>

        <li class="sidebar__nav__item--more" *ngIf="m.url == '/more'">
          <button
            mat-icon-button
            aria-label="Clique para exibir mais opções"
            class="button-more"
            #moreOptionsPop="bs-popover"
            [popover]="popMoreOptions"
            [outsideClick]="true"
            containerClass="custom custom-popover-nav"
            container="body"
            placement="bottom left"
          >
            <span class="material-symbols-outlined">more_horiz </span>
          </button>
        </li>
      </ng-container>
    </ul>
  </nav>
</div>

<ng-template #popMoreOptions>
  <div class="pop-menu">
    <ul class="list-unstyled pop-menu__list">
      <li class="pop-menu__item--icon-right">
        Ajustes da conta
        <span class="material-icons-outlined"> settings </span>
      </li>
      <li class="pop-menu__item--icon-right" (click)="logout()">
        Sair
        <span class="material-icons-outlined"> logout </span>
      </li>
    </ul>
  </div>
</ng-template>
