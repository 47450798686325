import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { SecurityService } from '../../shared/services/security.service';
import { CacheService } from 'src/app/shared/services/cache.service';

@Injectable({
  providedIn: 'root',
})
export class UnitGuard implements CanActivate {
  constructor(private router: Router, private cacheService: CacheService) {}
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const unit = this.cacheService.getUnit();
    if (!unit) {
      this.router.navigate(['/login/unidades']);
      return false;
    }
    return true;
  }
}
