import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { CacheService } from 'src/app/shared/services/cache.service';
import { environment } from 'src/environments/environment';

import { UserAuth } from '../model/class/userAuth';
import { ILogin } from '../model/interfaces/ILogin';

@Injectable({
  providedIn: 'root',
})
export class SecurityService {
  private loggedIn = new BehaviorSubject<boolean>(false);
  private userAuth: UserAuth = new UserAuth();

  /**
   * Método que verifica se o usuário está logado
   */
  get isLoggedIn(): Observable<boolean> {
    if (this.cacheService.checkUserAuth()) {
      this.loggedIn.next(true);
    }
    return this.loggedIn.asObservable().pipe(distinctUntilChanged());
  }

  constructor(private router: Router, private http: HttpClient, private cacheService: CacheService) {
    this.userAuth = this.cacheService.getLoggedUserInfo();
  }

  /**
   * Método que faz o login, setando o Observable como true e direcionando para a rota principal
   */
  login(): any {
    this.loggedIn.next(true);
    this.router.navigate(['/login/unidades']);
  }

  /**
   * Método que faz o logout, setando o Observable como false e direcioando para a rota de login
   */
  logout(): any {
    this.loggedIn.next(false);
    this.router.navigate(['/login']);
    this.cacheService.removeUserAuth();
  }

  /**
   * Método que envia o formulario de login e salva o token na sessionStorage ou localStorage
   * @param loginForm formulario de login
   */
  sendLogin(loginForm: ILogin) {
    return this.http.post<UserAuth>(`${environment.loginAPI}/Auth`, loginForm).pipe(
      map((data) => {
        if (data && data.authenticated) {
          this.cacheService.setLoggedUserInfo(data, loginForm.rememberMe);
        }
        return data;
      })
    );
  }

  /**
   * Método que envia o formulario de recuperação de senha para o serviço
   * @param recoveryForm formulário de recuperação de senha
   */
  passwordRecovery(email: string): Observable<any> {
    return this.http.post<{ success: boolean }>(`${environment.loginAPI}/MgrRecoverPassword`, {
      email,
    });
  }

  isPermissionValid(permissionValue: string): boolean {
    let ret = false;
    if (this.userAuth && this.userAuth.permisions) {
      ret = this.userAuth.permisions.find((p) => p === permissionValue) != null;
    }
    return ret;
  }

  hasPermission(permissionValue: string): boolean {
    return this.isPermissionValid(permissionValue);
  }

  hasAnyPermission(permissionValue: string[]): boolean {
    return permissionValue.some((item) => this.isPermissionValid(item));
  }

  hasPermissions(permissionValue: string[]): boolean {
    let result = true;

    permissionValue.forEach((claim) => {
      if (!this.isPermissionValid(claim)) {
        result = false;
      }
    });

    return result;
  }
}
