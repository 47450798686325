import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {
  previousUrl: string;

  constructor(private route: ActivatedRoute, private router: Router, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (this.previousUrl) {
          let arr = this.previousUrl.split('/');
          arr.forEach((item) => {
            this.renderer.removeClass(document.body, item);
          });
        }
        let currentUrlSlug = event.url.slice(1);
        if (currentUrlSlug) {
          let arr = currentUrlSlug.split('/');
          arr.forEach((item) => {
            this.renderer.addClass(document.body, item);
          });
        }
        this.previousUrl = currentUrlSlug;
      }
    });

    window.addEventListener('resize', (e) => {
      e.preventDefault();
      const panelActive = document.querySelector('ng-dropdown-panel') as HTMLElement;
      const dropActive = document.querySelector('ng-select.ng-select-opened') as HTMLElement;

      setTimeout(() => {
        if (panelActive && dropActive) {
          panelActive.style.left = dropActive.getBoundingClientRect().x + 'px';
        }
      }, 300);
    });
  }
}
