import { HttpClient } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';

import { environment } from './../../environments/environment';
import { CacheService } from '../shared/services/cache.service';

@Injectable({
  providedIn: 'root',
})
export class CoreService {
  // emitter para receber as notificacoes de push
  sendNotification = new EventEmitter<any>();

  constructor(private http: HttpClient, private cacheService: CacheService) {}

  /**
   * Método que faz a requisição para checar se o usuário possui notificações
   */
  checkNotificationsByUser() {
    return this.http.get<number>(
      environment.notificationAPI +
        'CheckNotificationByUser/' +
        this.cacheService.getUserID()
    );
  }

  /**
   * Método que faz a requisição para vir a listagem das notificações
   * @param skip number página da listagem
   * @param take number quantidade de registros
   */
  getListNotificationByUser(skip: number, take: number) {
    return this.http.post<{ more: number; notifications: any }>(
      environment.notificationAPI + 'ListNotificationByUser',
      {
        UserID: this.cacheService.getUserID(),
        Skip: skip,
        Take: take,
      }
    );
  }

  /**
   * Método que faz a requisição para a listagem das reservas pela busca
   * @param name String termo buscado
   */
  getBookingByName(name: string) {
    return this.http.post<Array<any>>(
      environment.bookingAPI + 'GetBookingByName',
      {
        Name: name,
      }
    );
  }
}
